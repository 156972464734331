'use strict';

(function($){

    var circleTimeout;
    var $circle = undefined;
    var $stroke = undefined;
    var $lineH = undefined;
    var $lineV = undefined;

    var app = {
        // Initial vars
        width: 768,

       /* init: function() {
            $('.scrolleffect').on('click', function(event) {
                event.preventDefault();
                app.smoothScroll(this);
            });
            this.cookiesAccept();
            return false;
        },*/

        init: function() {

            function loadJS(u){var r=document.getElementsByTagName("script")[0],s=document.createElement("script");s.src=u;r.parentNode.insertBefore(s,r);}

            if(!window.HTMLPictureElement || !('sizes' in document.createElement('img'))){
                //console.log('HTMLPictureElement no soportado');
                loadJS("ls.respimg.min.js");
            }



// Resize player
function resizePlayer(iframes, ratio) {
  if (!iframes[0]) return;
  var win = $(".main-slider"),
    width = win.width(), 
    playerWidth,
    height = win.height(),
    playerHeight,
    ratio = ratio || 16 / 9;

  iframes.each(function() {
    var current = $(this);
    if (width / ratio < height) {
      playerWidth = Math.ceil(height * ratio);
      current
        .width(playerWidth)
        .height(height)
        .css({
          left: (width - playerWidth) / 2,
          top: 0
        });
    } else {
      playerHeight = Math.ceil(width / ratio);
      current
        .width(width)
        .height(playerHeight)
        .css({
          left: 0,
          top: (height - playerHeight) / 2
        });
    }
  });
}

// Resize event
$(window).on("resize.slickVideoPlayer", function() {
  //resizePlayer(iframes, 16 / 9);
});
            this.cookiesAccept();
            //this.dropdownMenu();
            this.scrollEffect();
            this.parallax();
            this.industryJs();
            this.slickSlider();
            this.networkPaises();
            this.formValidate();
            this.slickProducto();
            this.modalWebinar();
            this.modalSectors();
            this.statesOnUsa();
            this.formCookiesAccept();


            return false;
        },
        fix: function() {
            var menuFix = $('.main-header');
            menuFix.scrollToFixed();            
        },      
        ajaxCall: function(url, type, dataType, $dataObj, cache, crossDomain) {
            // Default values if undefined values comes
            if (typeof type === 'undefined') type = 'GET';
            if (typeof dataType === 'undefined') {
                /**
                    "xml": Returns a XML document that can be processed via jQuery.
                    "html": Returns HTML as plain text; included script tags are evaluated when inserted in the DOM.
                    "script": Evaluates the response as JavaScript and returns it as plain text. Disables caching by appending a query string parameter, _=[TIMESTAMP], to the URL unless the cache option is set to true. Note: This will turn POSTs into GETs for remote-domain requests.
                    "json": Evaluates the response as JSON and returns a JavaScript object. Cross-domain "json" requests are converted to "jsonp" unless the request includes jsonp: false in its request options. The JSON data is parsed in a strict manner; any malformed JSON is rejected and a parse error is thrown. As of jQuery 1.9, an empty response is also rejected; the server should return a response of null or {} instead. (See json.org for more information on proper JSON formatting.)
                    "jsonp": Loads in a JSON block using JSONP. Adds an extra "?callback=?" to the end of your URL to specify the callback. Disables caching by appending a query string parameter, "_=[TIMESTAMP]", to the URL unless the cache option is set to true.
                    "text": A plain text string.
                 */
                dataType = 'html';
            }
            if (typeof dataToSend === 'undefined') dataToSend = {};
            if (typeof crossDomain === 'undefined') crossDomain = false;

            /* 99% percent times, is a form. By default serialize */
            if (typeof $dataObj === 'object') {
                dataToSend = $dataObj.serialize();
            }

            $.ajax({
                type: 'POST',
                url: "/",
                data: { data: dataToSend },
                dataType: "html",
                cache: false,
                async: true,
                crossDomain: crossDomain,
                timeout: 5,
                xhr: function()
                {
                    var xhr = new window.XMLHttpRequest();
                    //Upload progress
                    xhr.upload.addEventListener("progress", function(evt){
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            //Do something with upload progress
                        }
                    }, false);
                    //Download progress
                    xhr.addEventListener("progress", function(evt){
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            //Do something with download progress
                        }
                    }, false);

                    return xhr;
                },
                beforeSend: function (xhr, settings) {
                    // code here
                },
                complete: function (xhr, status) {
                    // code here
                },
                success: function (response) {
                    // code here
                },
                error : function (xhr, textStatus, errorThrown) {
                    // code here
                },
            });
            return true;
        },
        cleanUri: function(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to   = "aaaaeeeeiiiioooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }
            str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

            return str;
        },
/*        dropdownMenu: function() {
            if ($(window).width() > this.width) {
                $('.dropdown').hover(function() {
                  $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn();
                }, function() {
                  $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut();
                });

                $('.dropdown').removeClass('open');
                $('.dropdown-toggle').removeAttr("data-toggle");
            } else {
                $('.dropdown-menu').removeAttr('style');
                $('.dropdown').off('mouseenter mouseleave');
                //$('.dropdown-toggle').data('toggle', 'dropdown');
                $(".dropdown-toggle").attr("data-toggle", "dropdown");
            }
        },
*/
        togglePaises: function(value) {
            if(typeof value=='undefined') value=0;
            // $('a[data-idregion='+value+']').addClass("apais");
            $(".apais").addClass("hidden");
            $('li[data-idregion='+value+']').removeClass("hidden");
            return;
        },

        smoothScroll: function($obj) {
            var target = $($obj.hash);
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 50
                }, 1000);
            }
        },

       scrollEffect: function() {
            $('.scrolleffect').on('click', function(event) {
                event.preventDefault();
                app.smoothScroll(this);
            });
        },
        networkPaises: function() {
            if ($(".box-network").length > 0) {
                this.togglePaises(idregion); //necesitamos idregion: $idregion["idregion"]
                $(".network-btn").on('click',function (){
                    var $this = $(this);
                    $(".box-network").removeClass("hidden");
                app.togglePaises($this.attr("data-id"));
                });
            }
        },
        formValidate: function() {
            $.validate();
        },
        slickProducto: function() {
            $( document ).ready(function() {
            	var $div_hi = $('#heads-info');
            	$div_hi.on('show.bs.collapse','.collapse', function() {
            		$div_hi.find('.collapse.in').collapse('hide');
            	});
            	//$('.a[data-toggle="collapse"]').on('show.bs.collapse', function (e) { // evento que se dispara después de mostrar un tab
            	//	$("#heads-info").find('.slider-custom').slick('setPosition');
            	//});
            	$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) { // evento que se dispara después de mostrar un tab
            		$("#technology-info").find('.slider-custom').slick('setPosition');
            		$("#working-info").find('.slider-custom').slick('setPosition');
            	});  	
            });
        },
        slickSlider: function() {
            var slickEl = $('.slick-slider');
            slickEl.slick();
        },
        parallax: function() {
            if ( $(window).width() > 800 ) {
              var top_header = $('.bg-parallax');
              top_header.css({'background-position':'center center'}); // better use CSS
              $(window).scroll(function () {
                var st = $(this).scrollTop();
                top_header.css({'background-position':'center calc(50% + '+(st*.5)+'px)'});
              });
            }
        },
        industryJs: function() {
           $(".industry-js").mouseover(function() {
                var e = $(this),
                    a = e.attr("data-pointer");
                return $(".industry-js").parent().removeClass("active"),
                e.parent().addClass("active"),
                $(".industry-bg").css("z-index", "0").removeClass("selected"),
                $("." + a).css("z-index", "1").addClass("active selected")
            });
          },
        cookiesAccept: function() {
            if ($('#cookies-popup') && $.cookie('acepta_cookies')!== 'ok') {
                setTimeout( function() {$('#cookies-popup').animate({height:"toggle"},'fast','linear'); }, 3000);
                $('#cookie-btn').click(function(event){
                    event.preventDefault();
                    $('#cookies-popup').animate({height:"toggle"},'fast','linear');
                    $.cookie('acepta_cookies', 'ok', { expires: 7, path: '/' });
                });
            }
        },
        menu: function() {
            var mainHeader = $('.main-header'),
                btnNavSecondary = $('.nav-trigger');

            mainHeader.on('click', '.main-header__btn', function(event){
                btnNavSecondary.removeClass('active')
                mainHeader.toggleClass('main-header--open');
            });

            btnNavSecondary.on('click', function(event){
              $(this).toggleClass('active');
              mainHeader.removeClass('main-header--open')
            });
     
        },
        search: function() {
            var $searchBtn = $('#search-trigger');
            var $search = $('.search-c');
            var $searchInput = $('.search-c__input');
            var $searchClose = $('#search-close');

            $searchBtn.on('click', function(){
                $search.toggleClass('search-c--open');
                $searchInput.focus();
            });
            $searchClose.on('click', function(){
                $search.removeClass('search-c--open');
            });
        },
        drawCircle: function(el){
            var interval = 1;
            var angle = 0;
            var angle_increment = 4;
            window.timer = window.setInterval(function () {
                $stroke.attr("stroke-dasharray", angle + ", 20000");
                if (angle >= 360) {
                    window.clearInterval(window.timer);
                }
                angle += angle_increment;
            }.bind(this), interval);
            TweenMax.set($lineH, {width:'0'});
            TweenMax.set($lineV, {height:'0'});
            TweenMax.to($lineH, .6, {width:'100%', delay:0.2, ease:Quint.easeOut});
            TweenMax.to($lineV, .6, {height:'100%', delay:0.4, ease:Quint.easeOut});
            return;
        },
        clearCircle: function(){
            window.clearInterval(window.timer);
            clearTimeout(circleTimeout);
            $stroke.attr("stroke-dasharray", '20000');
            return;
        },
        animationCircle: function() {
            $circle.on("mouseenter", function() {
                app.clearCircle();
                app.drawCircle($(this));
            }).on("mouseleave", function() {
                app.clearCircle();
            });
        },
        scrollCollapse: function(){
            $('.head-info-collapse').on('shown.bs.collapse', function (e) {
                $('html,body').animate({
                    scrollTop: $(this).offset().top
                }, 500); 
            });
        },
        modalWebinar: function()
        {
            $('.btn-webinar-suscribe').click(function (e)
            {
                e.preventDefault();
                var modalTarget =  $($(this).data('target'));
                modalTarget.find('input[name="identificador"]').val($(this).data('webinar'));
                modalTarget.modal();
            })
        },
        modalSectors: function()
        {
            $('.btn-sector-download').click(function (e)
            {
                e.preventDefault();
                var modalTarget =  $($(this).data('target'));
                modalTarget.find('input[name="documento"]').val($(this).data('download'));
                modalTarget.modal();
            })
        },
        statesOnUsa: function()
        {
            var selectCountries = $('.select-countries');
            var formGroupStates = $('.select-states')

            selectCountries.change(function ()
            {
                var country = selectCountries.val();
                if(country === '' && selectCountries.hasClass('selectpicker'))
                {
                    //Comprobamos si se ha pasado un valor ya, pero no lo ha recogido adecuadamente por el selectpicker
                    var value = selectCountries.parent().children('button').children('.filter-option').html();
                    if(value !== '') country = value.trim();
                }
                if(country === 'USA' || country === 'Estados Unidos')
                {
                    formGroupStates.show();
                    formGroupStates.find('select').attr('data-validation','required');
                }
                else
                {
                    formGroupStates.hide();
                    formGroupStates.find('select').removeAttr('data-validation');
                }
            })
        },
        formCookiesAccept : function () {
            $('.button-cookies-accept').click(function (e){
                e.preventDefault();
                Cookiebot.submitCustomConsent(true,true,true);
                $('.recaptcha-placeholder').show();
                setTimeout(function (){
                    location.reload()
                },500);
            })
        }
    };
    // DOM Ready
    $(function(){
        $circle = $('.circle-anim');
        $stroke = $circle.find(".stroke");
        $lineH = $circle.find(".line-h");
        $lineV = $circle.find(".line-v");
        app.init();
        app.fix();
        app.animationCircle();
        app.menu();
        app.search();
        app.scrollCollapse();

    });
    // Resize Window
    /*$(window).resize( function(){
        app.dropdownMenu();
    });*/
})(jQuery);